import styled, { css, keyframes } from 'styled-components';
import { colors, lgBaseColors, zIndex } from 'uiLivi/styles';
import { mediaMax, mediaMin } from '../../../common/layout';

export const Center = styled.div`
  position: absolute;
  top: calc(50% - ${props => props.size / 2}px);
  left: calc(50% - ${props => props.size / 2}px);
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  text-align: center;
  font-weight: bold;
  color: ${colors.font.light};
`;

export const Loader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${zIndex.loader};
  background-color: ${colors.background.base};
  transition: opacity ${props => props.fadeDuration}ms;
  opacity: ${props => (props.isLoaded ? 0.0 : props.isStatsEnabled ? 0.8 : 1)};
  transition-delay: ${props => (props.isLoaded ? props.fadeDelay : 0)}ms;
  pointer-events: none;
  display: ${props => (props.hide ? 'none' : 'block')};
  ${Center} {
    transition: opacity;
    opacity: ${props => (props.isLoaded ? 0.0 : 1)};
    transition-duration: ${props => (props.isLoaded ? props.fadeDuration : 0)}ms;
    ${props =>
      props.hide
        ? css`
            animation: none;
          `
        : null};
  }
`;

export const TextBlock = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: 450px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  opacity: ${props => (props.isLoaded ? 0 : 1)};
  transition: opacity 0.75s ease-in;
  transition-delay: 0.25s;
`;

export const LoaderInfoTextTop = styled.p`
  font-size: 20px;
  color: ${lgBaseColors.white};
  opacity: 0.5;
  padding: 0;
  margin-bottom: -60px;

  ${mediaMax(
    'medium',
    css`
      margin-bottom: -20px;
    `
  )}
`;

export const LoaderInfoTextBottom = styled.p`
  font-size: 20px;
  color: ${lgBaseColors.white};
  opacity: 0.5;
  padding: 0;
  margin-top: -60px;

  ${mediaMax(
    'medium',
    css`
      margin-top: -20px;
    `
  )}
`;

export const LoaderDistrictText = styled.p`
  font-size: 160px;
  text-align: center;
  color: ${lgBaseColors.white};
  font-family: 'demodernbold', serif;

  ${mediaMax(
    'medium',
    css`
      font-size: 100px;
      line-height: 90px;
    `
  )}

  ${mediaMax(
    'small',
    css`
      font-size: 50px;
      line-height: 50px;
    `
  )}
`;
