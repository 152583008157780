import Hls from 'hls.js';
import { useSoundStore } from 'services/SoundService';
import {
  Color,
  LinearFilter,
  LinearMipmapNearestFilter,
  MeshBasicMaterial,
  NearestFilter,
  sRGBEncoding,
  TextureLoader,
  VideoTexture,
} from 'three';

export const createVideo = (videoSrc, position) => {
  const video = document.createElement('video');
  video.setAttribute('playsinline', '');
  video.setAttribute('autoplay', '');
  video.setAttribute('loop', '');
  video.setAttribute('muted', '');
  video.loop = true;
  video.playsinline = true;
  video.muted = true;
  video.crossOrigin = 'anonymous';

  if (!videoSrc) return video;

  let hls = null;
  if (videoSrc.endsWith('m3u8')) {
    if (Hls.isSupported()) {
      const config = {
        //reduce initial loading cpu load
        maxMaxBufferLength: 15,
      };
      hls = new Hls(config);
      hls.loadSource(videoSrc);
      // eslint-disable-next-line no-console
      console.log('HLS attach videoSrc');
      hls.attachMedia(video);
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        useSoundStore.getState().playVideo(video, position);
      });
      hls.on(Hls.Events.FRAG_LOADING, () => {
        // eslint-disable-next-line no-console
        console.log('FRAG_LOADING');
      });
      hls.on(Hls.Events.ERROR, e => {
        // eslint-disable-next-line no-console
        console.log('ERROR', e);
      });
    } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
      video.src = videoSrc;
      video.addEventListener('loadedmetadata', () => {
        useSoundStore.getState().playVideo(video, position);
      });
    }
  } else {
    const source = document.createElement('source');
    source.src = videoSrc;
    video.append(source);
    useSoundStore.getState().playVideo(video, position);
  }

  return { video, hls };
};

function createDisplayMaterial(texture) {
  const material = new MeshBasicMaterial({ map: texture });
  material.color = new Color(0xffffff);

  texture.anisotropy = 16;
  texture.encoding = sRGBEncoding;
  texture.magFilter = LinearFilter;
  texture.flipY = false;

  //fastest
  // texture.generateMipmaps = false;
  // texture.minFilter = NearestFilter;

  //fast
  texture.generateMipmaps = false;
  texture.minFilter = LinearFilter;

  //nice
  // texture.generateMipmaps = true;
  // texture.minFilter = LinearMipmapNearestFilter;

  return material;
}

export const createVideoMaterial = (url, position) => {
  const { video, hls } = createVideo(url, position);
  const texture = new VideoTexture(video);

  const material = createDisplayMaterial(texture);
  material.addEventListener('dispose', () => {
    useSoundStore.getState().removeVideo(video);
    video.pause();
    texture.dispose();
    if (hls) {
      hls.detachMedia();
    }
  });
  return { material, video };
};

export const createImageMaterial = url => {
  const texture = new TextureLoader().load(url);
  const material = createDisplayMaterial(texture);
  material.map.generateMipmaps = true;
  material.map.minFilter = LinearMipmapNearestFilter;

  return { material };
};
