import React, { useEffect } from 'react';
import { Color } from 'three';
import { useThree } from '@react-three/fiber';
import { usePostStore } from 'three/Renderer/store';
import { useEnvironmentStore } from './store';
import { useSceneStore } from 'services/SceneService';
import Lights from './Lights';
import Sky from 'three/Sky';
import Water from 'three/Water';
import CubemapRGBM from './CubemapRGBM';
import { useDebugStore } from '../../../storage/debug';

export default function Environment({ environmentPreset }) {
  const env = useEnvironmentStore(state => state.environmentConfiguration);
  const debugMinimal3d = useDebugStore(state => state.getMinimal3d());

  useEffect(() => {
    useEnvironmentStore.getState().setPresetByName(environmentPreset);
  }, []);

  const fogColor = new Color(env.fog.color);

  const { gl, camera } = useThree();

  useEffect(() => {
    //configure camera
    gl.logarithmicDepthBuffer = true;
    camera.far = 12000;
    camera.near = 0.1;
    camera.fov = env.camera.fov;
    camera.updateProjectionMatrix();
  }, [env.camera.fov]);

  return (
    <>
      <fogExp2 attach="fog" color={fogColor} density={env.fog.density} />
      {!debugMinimal3d && (
        <>
          <CubemapRGBM />
          <Lights />
        </>
      )}
      {debugMinimal3d && (
        <>
          <hemisphereLight color={0x336699} groundColor={0x996666} />
          <Lights />
        </>
      )}
      {env.sky.enabled && <Sky size={10000} fadeY={0} config={env.sky} />}
      {env.water.enabled && <Water reflections={false} position={[0, env.water.seaLevel, 0]} config={env.sky} />}
    </>
  );
}
