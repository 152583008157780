import { useEffect, useRef, useState } from 'react';
import { useTheaterStore } from 'services/TheaterService';
import { useFrame } from '@react-three/fiber';

export function useDocumentClickListener(active, fn, ignoreRef = null) {
  const callback = e => {
    const clickTargetInIgnoreRef = ignoreRef !== null && ignoreRef.current.contains(e.target);
    if (!clickTargetInIgnoreRef) fn(e);
  };
  return useEffect(() => {
    if (active) document.addEventListener('click', callback);
    else document.removeEventListener('click', callback);
    return () => {
      document.removeEventListener('click', callback);
    };
  }, [active]);
}

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const getTeaserType = url => {
  if (url && url.endsWith) {
    if (url.endsWith('.jpeg') || url.endsWith('.jpg') || url.endsWith('.png')) return 'image';
    else if (url.endsWith('.mp4') || url.endsWith('m3u8')) return 'video';
  } else {
    return 'image';
  }
};

export function useTheaterContent(defaultContent) {
  const currentTalk = useTheaterStore(state => state.currentTheaterTalk);
  const upcomingTalk = useTheaterStore(state => state.upcomingTheaterTalk);
  const talkIsLive = useTheaterStore(state => state.talkIsLive);
  const [theaterContent, setTheaterContent] = useState(
    defaultContent
      ? {
          teaser: defaultContent.teaser,
          content: defaultContent.url,
          title: defaultContent.headline,
          teaserType: getTeaserType(defaultContent.teaser),
        }
      : {
          teaserType: 'image',
        }
  );
  useEffect(() => {
    if (currentTalk && talkIsLive) {
      setTheaterContent({
        teaser: currentTalk.providerPayload.liveStream.url,
        teaserType: getTeaserType(currentTalk.providerPayload.liveStream.url),
        content: currentTalk.providerPayload.liveStream.url,
        title: currentTalk.title,
      });
    } else if (currentTalk && !talkIsLive) {
      setTheaterContent({
        teaser: currentTalk.teaser,
        teaserType: getTeaserType(currentTalk.teaser),
        content: currentTalk.teaser,
        title: currentTalk.title,
      });
    } else if (!currentTalk && upcomingTalk) {
      setTheaterContent({
        teaser: upcomingTalk.teaser,
        teaserType: getTeaserType(upcomingTalk.teaser),
        content: upcomingTalk.teaser,
        title: upcomingTalk.title,
      });
    } else if (defaultContent) {
      setTheaterContent({
        teaser: defaultContent.teaser,
        teaserType: getTeaserType(defaultContent.teaser),
        content: defaultContent.url,
        title: defaultContent.headline,
      });
    } else {
      setTheaterContent({
        teaser: '',
        teaserType: 'video',
        content: '',
        title: 'No Talk is currently configured.',
      });
    }
  }, [currentTalk, upcomingTalk, talkIsLive, defaultContent]);

  return theaterContent;
}

export function useFrameAfterMount(callback, renderPriority) {
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);
  useFrame((state, delta, frame) => {
    if (mounted) callback(state, delta, frame);
  }, renderPriority);
}
