import create from 'utilities/zustand/create';
import userStore from 'storage/user';
import { useContentStore } from 'services/ContentService';
import { Howler } from 'howler';

export const useSoundStore = create((set, get) => ({
  isPlayAllowed: false,
  enabled: userStore.getSoundEnabled(),
  videos: [],

  init: () => {
    const autoPlay = () => {
      window.removeEventListener('touchstart', autoPlay);
      window.removeEventListener('mousedown', autoPlay);
      window.removeEventListener('keydown', autoPlay);
      get().allowPlay();
    };
    window.addEventListener('touchstart', autoPlay);
    window.addEventListener('mousedown', autoPlay);
    window.addEventListener('keydown', autoPlay);

    const video = document.createElement('video');
    video.style.position = 'fixed';
    video.style.left = '0px';
    video.style.top = '-1000px';
    video.style.width = '100px';
    video.style.height = '100px';
    video.style.zIndex = '0';
    video.playsInline = true;
    video.muted = false;
    video.autoPlay = false;
    video.controls = true;
    video.src = 'https://assets.virtual-experience.demodern.com/events/demo/contents/Lobby_Outdoor_Presentation_1.mp4';
    document.body.append(video);
    set({ iPhoneHackVideo: video });

    useContentStore.subscribe(() => {
      get().adjustVolume();
    });
  },

  allowPlay: () => {
    get().iPhoneHackVideo.play();
    setTimeout(() => {
      get().iPhoneHackVideo.pause();
      get().iPhoneHackVideo.remove();
    }, 0);
    set({ isPlayAllowed: true });
    get().videos.forEach(video => {
      if (video.video) {
        try {
          video.play();
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      }
    });
    get().unmute();
  },

  unmute: () => {
    get().videos.forEach(video => {
      video.muted = false;
    });
  },

  playVideo: (video, position) => {
    const gain = Howler.ctx.createGain();
    gain.gain.setValueAtTime(0.5, Howler.ctx.currentTime);
    gain.connect(Howler.masterGain);

    const panner = Howler.ctx.createPanner();
    panner.coneInnerAngle = 360;
    panner.coneOuterAngle = 360;
    panner.coneOuterGain = 0;
    panner.distanceModel = 'exponential';
    panner.maxDistance = 10000;
    panner.refDistance = 20;
    panner.rolloffFactor = 10;
    panner.panningModel = 'HRTF';
    panner.positionX.setValueAtTime(position.x, Howler.ctx.currentTime);
    panner.positionY.setValueAtTime(position.y, Howler.ctx.currentTime);
    panner.positionZ.setValueAtTime(position.z, Howler.ctx.currentTime);
    panner.connect(gain);

    const source = Howler.ctx.createMediaElementSource(video);
    source.connect(panner);

    const videos = get().videos;
    videos.push(video);
    set({ videos });
    video.play();

    if (get().isPlayAllowed) {
      get().unmute();
    }
    get().adjustVolume();
  },

  removeVideo: video => {
    const videos = get().videos;
    const videoIndex = videos.indexOf(video);
    if (videoIndex > -1) {
      videos.splice(videoIndex, 1); // 2nd parameter means remove one item only
    }
    set({ videos });
  },

  setEnabled: active => {
    set({ enabled: active });
    userStore.setSoundEnabled(get().enabled);
    get().adjustVolume();
  },

  toggle: () => {
    set({ enabled: !get().enabled });
    userStore.setSoundEnabled(get().enabled);
    get().adjustVolume();
  },

  adjustVolume: () => {
    const { videos } = get();
    videos.forEach(video => {
      if (video) {
        if (useContentStore.getState().activeContent) {
          video.volume = 0;
        } else {
          video.volume = get().enabled ? 1 : 0;
        }
      }
    });
  },
}));
