import create from 'utilities/zustand/create';
import { useChatStore } from 'services/ChatService';
import { useContentStore } from 'services/ContentService';
import { useTheaterStore } from 'services/TheaterService';
import { useEventStore } from 'services/EventService';
import { useCronService } from 'services/CronService';
import { useOnboardingStore } from 'services/OnboardingService';
import { useProfilingStore } from '../ProfilingService';
import { usePlayerStore } from 'services/PlayerService';
import { CONTENT_TYPE } from './contentTypes';
import { useHowlerStore } from 'services/HowlerService';
import { useDistrictWarmupStore, WarmupPhase } from 'services/DistrictWarmupService';

let socket = null;
export const useDistrictStore = create((set, get) => ({
  districts: [],
  district: null,

  init: managedSocket => {
    socket = managedSocket;
    return new Promise(accept => {
      set({ districts: useEventStore.getState().event.districts });
      accept();
    });
  },

  navigateToDistrict: (history, mapId) => {
    if (useDistrictWarmupStore.getState().phase === WarmupPhase.DONE) {
      useDistrictWarmupStore.setState({ nextMapId: mapId });
      useDistrictWarmupStore.getState().setPhase(WarmupPhase.IDLE);
      setTimeout(() => {
        history.push(`/play/${mapId}`);
      }, 500);
    }
  },

  initDistrict: district => {
    set({ district: district });

    const updateContents = () => {
      // add teaser type by extension
      const contents = useEventStore.getState().event.contents.map(content => {
        return {
          ...content,
          teaserType: content.teaser ? getTeaserType(content.teaser) : 'image',
          contentType: CONTENT_TYPE[content.type],
          headline: content.headline ? content.headline : '',
          subline: content.subline ? content.subline : '',
        };
      });
      useContentStore.getState().setContents(contents);
    };

    //why? dont know why contents are set when talk is changed
    useTheaterStore.subscribe(
      () => {
        updateContents();
      },
      state => state.currentTheaterTalk
    );

    updateContents();
  },

  enterDistrict: district => {
    const { districts } = get();
    district.entered = true;
    set({ districts: [...districts] });
    useProfilingStore.getState().mark('Enter District');
    socket.emit('district/enter', district.room, () => {});
    useHowlerStore.getState().startDistrictHowls(district.room);
    useChatStore.getState().switchDistrict();
    useCronService.getState().removeJob('talkNotifications');
    useCronService.getState().addJob({ type: 'talkNotifications' });
  },

  leaveDistrict: district => {
    const { districts } = get();
    district.entered = false;
    district.isLoaded = false;
    set({ district: null, districts: [...districts] });

    socket.emit('district/leave');
    usePlayerStore.getState().leaveDistrict();
    useHowlerStore.getState().stopDistrictHowls();
  },

  setDistrictLoaded: () => {
    const { districts, district } = get();
    if (!district) return;
    district.isLoaded = true;
    set({ districts: [...districts], district: district });
  },

  logout: () => {
    const { districts, district } = get();
    if (district) {
      get().leaveDistrict(district);
    }
    if (districts) {
      set({ districts: [...districts], district: null });
    }
  },

  getSimpleDistrictList: () => {
    return get().districts.map(d => ({ id: d.room, name: d.title }));
  },

  getSimpleDistrictListWithPOV: (isExpert = false) => {
    const list = [];
    get().districts.forEach(district => {
      if (district.isExpertOnlyDistrict && !isExpert) return;
      list.push({ id: district.room, name: district.title });
      if (district.povs) {
        district.povs.forEach(pov => {
          list.push({
            type: 'pov',
            room: pov.room,
            id: pov.id,
            name: pov.title,
            location: pov.location,
            euler: pov.euler,
          });
        });
      }
    });
    return list;
  },
}));

export const getTeaserType = url => {
  if (url.endsWith('.jpeg') || url.endsWith('.jpg') || url.endsWith('.png')) return 'image';
  else if (url.endsWith('.mp4') || url.endsWith('m3u8')) return 'video';
};
