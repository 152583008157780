import React, { Suspense, useEffect, useRef } from 'react';
import { CanvasTexture, Color, ImageLoader } from 'three';
import { useFrame, useLoader } from '@react-three/fiber';
import Mic from './assets/microphone.png';
import { useGlobalHubStore } from 'services/GlobalHubService';
import { lgBaseColors } from '../../../uiLivi/styles';
import { useDistrictStore } from '../../../services/DistrictService';
import { createNoise2D } from 'simplex-noise';
import { Billboard } from '@react-three/drei';

const PARAMS = {
  radius: 50,
  steps: 60,
  radStep: 0,
  size: 256,
  halfSize: 128,
};

const noise2D = createNoise2D();

function CanvasTextureSprite({ playerPosition, userId }) {
  const sprite = useRef();
  const spriteMaterial = useRef();
  const ctx = useRef(null);
  const texture = useRef(null);
  const resultColor = useRef(new Color(0xffffff));
  const baseColor = useRef(new Color(lgBaseColors.red.base));
  const highlightColor = useRef(new Color(lgBaseColors.red.highlight));
  const [mic] = useLoader(ImageLoader, [Mic]);

  useEffect(() => {
    ctx.current = document.createElement('canvas').getContext('2d');
    ctx.current.canvas.width = PARAMS.size;
    ctx.current.canvas.height = PARAMS.size;
    ctx.current.lineWidth = 3;
    ctx.current.strokeStyle = '#ffffff';
    texture.current = new CanvasTexture(ctx.current.canvas);
    PARAMS.radStep = (360 / PARAMS.steps) * 0.0174533;
  }, []);

  const drawCircle = (level, t) => {
    ctx.current.clearRect(0, 0, ctx.current.canvas.width, ctx.current.canvas.height);
    ctx.current.drawImage(mic, 0, 0, 256, 256);

    resultColor.current.lerpColors(baseColor.current, highlightColor.current, level);
    ctx.current.strokeStyle = '#' + resultColor.current.getHexString();

    for (let i = 0; i < PARAMS.steps; i++) {
      const phi = i * PARAMS.radStep;
      const len = 1 + level * (5 + Math.abs(noise2D(i * 0.2 + t, t)) * 15);
      const x1 = PARAMS.halfSize + PARAMS.radius * Math.sin(phi);
      const y1 = PARAMS.halfSize + PARAMS.radius * Math.cos(phi);
      const x2 = PARAMS.halfSize + (PARAMS.radius + len) * Math.sin(phi);
      const y2 = PARAMS.halfSize + (PARAMS.radius + len) * Math.cos(phi);

      ctx.current.beginPath();
      ctx.current.moveTo(x1, y1);
      ctx.current.lineTo(x2, y2);
      ctx.current.stroke();
    }

    if (texture.current && spriteMaterial.current) {
      texture.current.needsUpdate = true;
      spriteMaterial.current.needsUpdate = true;
    }
  };

  useFrame((state, delta) => {
    if (sprite.current && playerPosition) {
      sprite.current.position.set(playerPosition[0], playerPosition[1] + 1.75, playerPosition[2]);
      const micLevel = useGlobalHubStore.getState().getMicLevel(userId);
      drawCircle(micLevel, state.clock.elapsedTime);
    }
  }, 1000);

  return (
    <>
      {/*<Billboard ref={billboard}>*/}
      {/*  <mesh scale={[0.5, 0.5, 0.5]}>*/}
      {/*    <planeGeometry />*/}
      {/*    <meshBasicMaterial map={texture.current} transparent={true} opacity={0.99} />*/}
      {/*  </mesh>*/}
      {/*</Billboard>*/}

      <sprite ref={sprite} scale={[0.15, 0.15, 0.15]} center={[0.5, 0.0]}>
        <spriteMaterial ref={spriteMaterial} sizeAttenuation={false} map={texture.current} />
      </sprite>
    </>
  );
}

export default function AudioActivityDisplay({ playerPosition, userId }) {
  const district = useDistrictStore(state => state.district);
  const isGlobalVoice = district?.isGlobalVoiceArea;

  if (!isGlobalVoice) return;

  return (
    <Suspense fallback={null}>
      <CanvasTextureSprite playerPosition={playerPosition} userId={userId} />
    </Suspense>
  );
}
