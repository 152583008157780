import React, { useRef } from 'react';
import AnimationController from 'components/Play/Character/AnimationController';
import { globalPlayers, useGlobalHubStore } from 'services/GlobalHubService';
import * as GLM from 'gl-matrix';
import { interpolatePosRot } from 'utilities/physics';
import AudioActivityDisplay from '../AudioActivityDisplay';
import { useUserStore } from 'services/UserService';

const GlobalPlayer = ({ userId, isFitting }) => {
  const group = useRef();
  const scale = 1.0;

  const user = useUserStore(state => state.user);
  const isSelf = user.id === userId;

  if (!isSelf && isFitting) return null;

  const playerData = useGlobalHubStore(state => state.users[userId]);
  const appearance = useGlobalHubStore(state => state.users[userId].appearance);

  const pos = useRef(null);

  if (!playerData) return null;

  const { reaction } = playerData;

  return (
    <>
      <group ref={group}>
        <AnimationController
          group={group}
          isSelf={isSelf}
          getPlayerData={dt => {
            if (!isSelf) {
              const { position, velocity, rotation } = playerData;
              const pNet = GLM.vec3.fromValues(...position);
              if (!pos.current) {
                pos.current = pNet;
              }
              const pCurrent = GLM.vec3.fromValues(...pos.current);
              const dist = GLM.vec3.dist(pCurrent, pNet);
              if (dist > 10) {
                GLM.vec3.copy(pCurrent, pNet);
              }

              const vel = GLM.vec3.fromValues(...velocity);
              const res = interpolatePosRot(pCurrent, rotation, pNet, rotation, vel, dt);
              pos.current = res.position;
              return {
                ...playerData,
                position: res.position,
              };
            } else {
              return playerData;
            }
          }}
          scale={[scale, scale, scale]}
          reactionId={reaction}
          appearance={appearance}
        />
      </group>
      <AudioActivityDisplay playerPosition={pos.current} userId={userId} />
    </>
  );
};

export default function GlobalPlayers({ isFitting }) {
  const userIds = useGlobalHubStore(state => state.userIds);
  if (userIds.length === null) return null;
  return userIds.map((userId, i) => <GlobalPlayer isFitting={isFitting} userId={userId} key={i} />);
}
