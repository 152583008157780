import { usePlayerStore } from 'services/PlayerService';
import { useUserStore } from 'services/UserService';
import create from 'utilities/zustand/create';
import { object } from 'prop-types';
import { DEFAULT_APPEARANCE } from '../../components/Play/GlobalPlayers/consts';
import { interpolatePosRot } from '../../utilities/physics';

let socket = null;

export const globalPlayers = {};
const localMic = {
  level: 0,
};

let lastSendMs = 0;

export const useGlobalHubStore = create((set, get) => ({
  userIds: [],
  users: {},
  ivId: 0,
  dirty: false,
  init: managedSocket => {
    socket = managedSocket;

    socket.on('globalHub/receive', data => {
      const t = globalPlayers[data.userId]?.t || Date.now();
      const netDt = Math.min(Date.now() - t, 1000);
      globalPlayers[data.userId] = { ...data, netDt };
      set({ userIds: Object.keys(globalPlayers).map(a => Number(a)) });
      set({ users: globalPlayers });
    });

    socket.on('globalHub/disconnect', id => {
      delete globalPlayers[id];
      set({ userIds: Object.keys(globalPlayers).map(a => Number(a)) });
      set({ users: globalPlayers });
    });

    const ivId = setInterval(() => {
      if (!useUserStore.getState().user) return;

      const tenSecondsAgo = Date.now() - 10000;
      Object.values(globalPlayers)
        .filter(p => p.t < tenSecondsAgo)
        .forEach(p => {
          delete globalPlayers[p.userId];
          set({ userIds: Object.keys(globalPlayers).map(a => Number(a)) });
        });
      const userId = useUserStore.getState().user.id;

      const isExpert = useUserStore.getState().user.role.type === 'expert';
      if (isExpert && !globalPlayers[userId]) {
        // eslint-disable-next-line no-console
        console.warn('Not receiving from global hub');
      }
      set({ dirty: true });
      set({ users: globalPlayers });
      get().transmitPlayerState();
    }, 1000);

    set({ ivId });
  },
  exit: () => {
    clearInterval(get().ivId);
  },
  setMicLevel: (userId, micLevel) => {
    const { user } = useUserStore.getState();
    const isSelf = String(user?.id) === String(userId);
    if (isSelf) {
      localMic.level = micLevel;
    }
  },
  getMicLevel: userId => {
    const { micLevel } = globalPlayers[userId];
    return micLevel;
  },
  updatePosition: () => {
    const { user } = useUserStore.getState();
    const { id: userId, role } = user;
    const isGlobalPlayer = role.type === 'expert';
    if (!isGlobalPlayer) return;
    const { position, rotation, velocity } = usePlayerStore.getState();
    const player = get().users[userId];
    player.position = position;
    player.rotation = rotation;
    player.velocity = velocity;
    get().transmitPlayerState();
  },
  updateAppearance: appearance => {
    const user = useUserStore.getState().user;
    if (user.role.type !== 'expert') return;
    get().users[user.id].appearance = appearance;
    get().transmitPlayerState();
  },
  transmitPlayerState: () => {
    const { user } = useUserStore.getState();
    const { id: userId, role } = user;
    const isGlobalPlayer = role.type === 'expert';
    if (!isGlobalPlayer) {
      return;
    }
    const appearance = get().users[userId] ? get().users[userId].appearance : user.appearance;
    const { position, rotation, velocity, reaction, input } = usePlayerStore.getState();
    const { dirty } = get();
    const data = {
      userId,
      appearance,
      position,
      rotation,
      velocity,
      reaction,
      input,
      t: Date.now(),
      micLevel: localMic.level,
    };

    if (Date.now() - lastSendMs > 50) {
      lastSendMs = Date.now();
      if (dirty) {
        set({ dirty: false });
        socket.emit('globalHub/transmit', data);
      }
    }
  },
}));
