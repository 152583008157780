import create from 'utilities/zustand/create';

import { useUserStore } from 'services/UserService';
import { useDistrictStore } from 'services/DistrictService';
import { useContentStore } from 'services/ContentService';
import { useNotificationStore } from 'services/NotificationService';
import ChatToast from 'uiLivi/components/Chat/ChatToast';
import { trackSend } from 'services/TrackingService/ChatTracking';
import { useTranslationStore } from 'services/TranslationService';
import { useEventStore } from '../EventService';
import axios from 'axios';

let chatSeenRuntimeFlag = true;

const prepareChat = chat => {
  const translate = useTranslationStore.getState().translate;
  chat.messages = [];
  if (chat.type === 'private' || chat.type === 'support') {
    const selfId = useUserStore.getState().user.id;
    chat.name = chat.users
      .filter(user => user.id !== selfId)
      .map(user => user.forename)
      .join(', ');
  } else if (chat.type === 'content') {
    chat.name = translate('chat.room.content') || 'Content Chat';
  } else {
    chat.name = chat.identifier + ' Chat';
  }

  chat.seen = chatSeenRuntimeFlag;
  chat.last = false; // server tells us if that was the last page, the client cannot extrapolate that information
};

let socket = null;

const initialState = chats => {
  return {
    chats: chats ? chats : [],
    activeChat: null,
    isActive: false,
    pending: false,
    initialLoadId: null,
    isFullHeight: false,
    isLargeInput: false,
    isShiftedByVideoChat: false,
    userDidScrollUp: false,
    scrollIsTop: false,
    chatType: 'default',
    toast: null,
    emoji: null,
  };
};

export const useChatStore = create((set, get) => ({
  ...initialState(),

  reset: () => {
    const chats = get().chats;
    const preservedDistrictChats = chats.filter(c => c.type === 'district');
    set({ ...initialState(preservedDistrictChats) });
  },

  init: managedSocket => {
    socket = managedSocket;

    const { pushChat } = get();
    socket.emit('chat/list', chats => {
      chats.forEach(chat => pushChat({ ...chat }));
    });

    socket.on('chat/message', message => get().pushMessage(message));
    socket.on('chat/emoji', emoji => {
      set({ emoji });
    });

    socket.on('message/hidden', message => {
      const user = useUserStore.getState().user;
      const chats = get().chats;
      const activeChat = get().activeChat;
      const chat = chats.find(c => c.id === message.chat);
      const isAuthenticatedUser = user.role.type === 'authenticated';
      if (isAuthenticatedUser) {
        chat.messages = chat.messages.filter(m => m.id !== message.id);
      } else {
        const messageToHide = chat.messages.find(m => m.id === message.id);
        if (!messageToHide) return;
        messageToHide.isHidden = true;
      }

      set({ ...chats });
      if (activeChat && chat.id === activeChat.id) {
        set({ activeChat: { ...chat } });
      }
    });

    socket.on('message/shown', message => {
      const user = useUserStore.getState().user;
      const isAuthenticatedUser = user.role.type === 'authenticated';
      const chats = get().chats;
      const activeChat = get().activeChat;
      const chat = chats.find(c => c.id === message.chat);
      if (!chat) return;
      if (!isAuthenticatedUser) {
        const messageToShow = chat.messages.find(m => m.id === message.id);
        if (!messageToShow) return;
        messageToShow.isHidden = false;
      } else {
        chat.messages.push(message);
        chat.messages = chat.messages.sort((a, b) => a.id - b.id);
      }

      set({ chats });
      if (activeChat && chat.id === activeChat.id) {
        set({ activeChat: { ...chat } });
      }
    });
  },

  enter: chat => {
    set({ activeChat: chat, isActive: true, pending: true, initialLoadId: null });
  },

  leave: () => {
    set({ activeChat: null });
  },

  setPending: isPending => {
    set({ pending: isPending });
  },

  hideMessage: messageId => {
    socket.emit('message/hide', messageId);
  },

  showMessage: messageId => {
    socket.emit('message/show', messageId);
  },

  setChatSeen: () => {
    const { activeChat: chat } = get();
    if (!chat) return;
    chat.seen = true;
    set({ activeChat: chat });
  },

  setFullHeight: isFullHeight => {
    set({ isFullHeight });
  },

  setLargeInput: isLargeInput => {
    set({ isLargeInput });
  },

  send: text => {
    const { activeChat } = get();
    if (activeChat == null) {
      // TODO: do not call send() if there is no active chat instead of bail out in service
      return;
    }
    socket.emit('message/send', { text, chatId: activeChat.id });
    trackSend(activeChat);

    const { shortname } = useEventStore.getState().event;
    axios.get(
      'https://metrics-collector.virtexp.virtual-events.dmdr.io/metric/' + shortname + '/incr_chatmessages_sent_total'
    );
  },

  sendEmoji: (text, userId) => {
    socket.emit('message/emoji', { text, userId });
    const { shortname } = useEventStore.getState().event;
    axios.get(
      'https://metrics-collector.virtexp.virtual-events.dmdr.io/metric/' +
        shortname +
        '/incr_emojis_sent_total?emoji=' +
        text
    );
  },

  start: (userIds, type = 'private') => {
    const chats = get().chats;
    const hasLocalChat = type === 'private' && chats.find(c => c.users.find(cU => cU.id === userIds[0]));
    if (hasLocalChat && hasLocalChat.length === 1) {
      get().enter(hasLocalChat);
      return;
    }
    socket.emit('chat/start', { userIds, type }, chat => {
      const { pushChat } = get();
      pushChat(chat);
      get().enter(chat);
    });
  },

  getChat: async chatId => {
    return new Promise(accept => {
      socket.emit('chat/get', chatId, chat => {
        get().pushChat({ ...chat });
        accept();
      });
    });
  },

  findById: chatId => {
    return get().chats.find(c => c.id === chatId);
  },

  loadMessages: (chat, fromScroll = false) => {
    if (!fromScroll) set({ pending: true });
    const payload = { chatId: chat.id };

    if (chat.messages.length > 0) {
      payload.messageId = chat.messages[0].id;
    }

    socket.emit('chat/getMessages', payload, result => {
      const { messages, last } = result;
      chat.messages.unshift(...messages);
      chat.messages = chat.messages.sort((a, b) => a.id - b.id);
      chat.last = last;
      set({ chats: [...get().chats], pending: false, initialLoadId: chat.id });
    });
  },

  pushChat: chat => {
    prepareChat(chat);
    chatSeenRuntimeFlag = true;
    let { chats } = get();
    // TODO: remove that filter, everything should work without "sanitization"
    chats = chats.filter(c => c.id !== chat.id);
    chats.push(chat);
    set({ chats });
  },

  sendToast: message => {
    const chat = get().chats.find(c => c.id === message.chat);
    if (!message.fromExpert && !(chat.type === 'private')) return;
    if (chat) chat.seen = false;
    message.sender = useUserStore.getState().users.find(u => u.id === message.user);
    if (!message.sender) useUserStore.getState().requestUser(message.user, true);
    useNotificationStore.getState().addNotification(<ChatToast key={message.id} message={message} />);
  },

  setUserDidScrollUp: userDidScrollUp => {
    set({ userDidScrollUp });
  },

  setScrollIsTop: scrollIsTop => {
    set({ scrollIsTop });
  },

  __applySeenStatus: chat => {
    const selfId = useUserStore.getState().user.id;
    const selfUserHasYetWrittenInChat = !!chat.messages.find(m => m.user === selfId);

    if (get().userDidScrollUp) {
      chat.seen = false;
    } else if (selfUserHasYetWrittenInChat) {
      chat.seen = selfUserHasYetWrittenInChat && !get().userDidScrollUp;
    }
  },

  pushMessage: async message => {
    const { chats, activeChat } = get();
    const chat = chats.find(c => c.id === message.chat);
    if (!chat) {
      chatSeenRuntimeFlag = false;
      await get().getChat(message.chat);
      const chat = chats.find(c => c.id === message.chat);
      get().sendToast(message);
      return;
    }
    chat.messages = chat.messages.filter(m => m.id !== message.id);
    chat.messages.push(message);

    get().__applySeenStatus(chat);

    const sendToastToUsers = activeChat === null || activeChat.id !== message.chat || !get().isActive;

    if (sendToastToUsers) {
      get().sendToast(message);
    }
    // const i = chats.indexOf(chat);
    // chats.splice(i, 1);
    // chats.push(chat);
    set({ chats: [...chats] });
    if (activeChat && chat.id === activeChat.id) {
      set({ activeChat: { ...chat } });
    }
  },

  switchDistrict: () => {
    const { activeChat, chats } = get();
    let update = { chats: chats.filter(c => c.type !== 'district') };
    const isDistrictChat = activeChat && activeChat.type === 'district';
    if (isDistrictChat) {
      update = { ...update, activeChat: null };
    }
    set(update);

    const { district } = useDistrictStore.getState();
    socket.emit('chat/district', district.room, chat => {
      const { pushChat } = get();
      pushChat(chat);
      if (isDistrictChat) {
        set({ activeChat: chat });
      }
    });
  },

  switchContent: () => {
    const state = useContentStore.getState();
    const contentActive = state.activeContent != null;
    if (!contentActive) {
      let { chats } = get();
      chats = chats.filter(c => c.type !== 'content');
      set({ chats, activeChat: null, chatType: 'default' });
      return;
    }

    set({ chatType: 'content' });

    if (state.activeContent.type.contentChatRoomEnabled) {
      const { district } = useDistrictStore.getState();
      const { activeContent } = state;
      const { location } = activeContent;
      const chatIdentifier = district.room + '_' + location;

      socket.emit('chat/content', chatIdentifier, chat => {
        const { pushChat } = get();
        pushChat(chat);
        set({ activeChat: chat });
      });
    }
  },

  setIsActive: isActive => {
    set({ isActive });
  },

  shiftForVideoChat: () => {
    set({
      isShiftedByVideoChat: true,
    });
  },

  unshiftForVideoChat: () => {
    set({
      isShiftedByVideoChat: false,
    });
  },
}));
