import create from 'utilities/zustand/create';
import userStorage from 'storage/user';
import { useModalService } from 'services/ModalService';
import ControlOnboarding from 'components/Onboarding/ControlOnboarding';
import { useEventStore } from 'services/EventService';

export const useOnboardingStore = create((set, get) => ({
  done: userStorage.getOnboardingDone(),
  startOnboarding: () => {
    if (!get().done) {
      get().repeatOnboarding();
    }
  },
  repeatOnboarding: () => {
    const useAvatars = useEventStore.getState().event.useAvatars;
    useAvatars && useModalService.getState().addModal(<ControlOnboarding key="controls" />);
  },

  finishOnboarding: () => {
    userStorage.setOnboardingDone();
    set({ done: true });
  },
}));
