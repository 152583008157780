import styled, { css } from 'styled-components';
import { useEffect, useState } from 'react';
import { useInterval } from '../../../../utilities/hooks';
import { mediaMax } from '../../../../common/layout';

const Placer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  bottom: 15%;
  opacity: ${props => (props.show ? 1 : 0)};
  transition: opacity 0.75s ease-in;
  transition-delay: 0.5s;
`;

const Hint = styled.div`
  position: absolute;
  font-family: 'demodernlight', serif;
  font-size: 18px;
  width: max-content;
  opacity: ${props => (props.show ? 1 : 0)};
  transition: opacity 0.5s ${props => (props.show ? 'ease-in' : 'ease-out')};

  ${mediaMax(
    'small',
    css`
      font-size: 15px;
    `
  )}
`;

const HINT_DELAY = 2500;

export default function LoaderHintDisplay({ isLoaded, hints }) {
  const [currentId, setCurrentId] = useState(0);
  const [count, setCount] = useState(0);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setCount(hints.length);
    setShow(true);
  }, [hints]);

  const update = () => {
    if (currentId < count - 1) {
      setCurrentId(currentId + 1);
    } else {
      setCurrentId(0);
    }
  };

  useInterval(update, HINT_DELAY);

  return (
    <Placer show={show}>
      {hints.map((hint, index) => {
        return (
          <Hint show={currentId === index} key={index}>
            {hint}
          </Hint>
        );
      })}
    </Placer>
  );
}
