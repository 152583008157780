import React, { useMemo } from 'react';
import {
  InstructionSpacerBox,
  InstructionVisualBox,
  InstructionVisualCaption,
  InstructionVisualColumn,
  InstructionVisualContainer,
  SpacerColumn,
} from './styles';
import { ContentContainer, InstructionParagraph, Title, TitleContainer } from '../styles';
import CloseButton from 'common/components/CloseButton';
import HBFLayout from 'common/components/HBFLayout';
import { Button } from 'common/components/Button';
import JoystickIcon from './assets/joystick.svg';
import WasdIcon from './assets/wasd.svg';
import { useModalService } from 'services/ModalService';
import { useTranslate } from 'services/TranslationService/hooks';
import { useDeviceStore } from 'services/DeviceService';
import { useOnboardingStore } from 'services/OnboardingService';

export default function ControlOnboarding() {
  const device = useDeviceStore(state => state.device);
  const isTouchDevice = useMemo(() => device.isMobileOnly || device.isTablet, [device]);

  const closeModal = () => {
    useModalService.getState().closeModal();
    useOnboardingStore.getState().finishOnboarding();
  };
  const translate = useTranslate();
  const header = (
    <TitleContainer>
      <Title>{translate('onboarding.control.title') || 'Get started'}</Title>
      <CloseButton noBackground size={40} position={{ top: 0, right: 0 }} handler={closeModal} />
    </TitleContainer>
  );

  const body = (
    <ContentContainer>
      <InstructionParagraph>
        {isTouchDevice
          ? translate('onboarding.control.copy.mobile') || (
              <>Walk over to the highlighted zone. Move the Joystick to move your character.</>
            )
          : translate('onboarding.control.copy.desktop') || (
              <>
                Walk over to the highlighted zone. Move the Joystick with your mouse cursor or use WASD keys to move
                your character.
              </>
            )}
      </InstructionParagraph>
      <InstructionVisualContainer>
        <InstructionVisualColumn grow>
          <InstructionVisualBox>
            <JoystickIcon />
          </InstructionVisualBox>
          <InstructionVisualCaption>{translate('onboarding.control.joystick') || 'Joystick'}</InstructionVisualCaption>
        </InstructionVisualColumn>
        {!isTouchDevice && (
          <>
            <SpacerColumn>
              <InstructionSpacerBox>{translate('onboarding.control.or') || 'or'}</InstructionSpacerBox>
            </SpacerColumn>
            <InstructionVisualColumn grow>
              <InstructionVisualBox>
                <WasdIcon />
              </InstructionVisualBox>
              <InstructionVisualCaption>{translate('onboarding.control.keys') || 'Keys'}</InstructionVisualCaption>
            </InstructionVisualColumn>
          </>
        )}
      </InstructionVisualContainer>
    </ContentContainer>
  );

  const footer = (
    <>
      <Button accent fullWidth onClick={closeModal}>
        {translate('onboarding.control.confirm') || 'Got it!'}
      </Button>
    </>
  );

  return <HBFLayout header={header} body={body} footer={footer} />;
}
